.center-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-color: #1e3c5a; /* Use the same background color as the sidebar */
}

.logo {
  width: 100px; /* Adjust the width as needed */
  height: auto;
  margin-bottom: 20px;
}

.center-form h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: white;
}

.center-form Form {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #1e3c5a; /* Use the same background color as the sidebar */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.center-form Form Label {
  font-weight: bold;
}

.center-form Form .form-control {
  margin-bottom: 20px;
  padding: 10px;
}

.center-form Form .btn {
  background-color:black; /* Change to a professional color of your choice */
  color: #fff;
  border: none;
  padding: 10px 0;
}

.center-form Form .btn:hover {
  background-color: rgb(49, 47, 47); /* Change to a darker shade for hover effect */
}

