/* Add your styles here */
.navbar-style {
  background-color: #1e3c5a;
}

.offcanvas-style {
  background-color: rgb(76, 76, 76);
}

.offcanvas-body-style {
  background-color: #1e3c5a;
}

/* Set the color of the links to white */
.nav-link,
.navbar-toggler-icon {
  color: white !important;
}

/* Set the color of the links when hovered */
.nav-link:hover {
  color: #f8f9fa; /* You can adjust the hover color as needed */
}
