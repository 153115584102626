.sendXAF-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 100%;
}

.full-width {
    width: 100%;
}

.amount-input::-webkit-inner-spin-button,
.amount-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sendXAFButton {
    margin-top: 30px;
    margin-left: 30px;
}
